<template>
  <div v-if="notificacion">
    <div class="card">
      <h3 class="card-title">Editar notificacion</h3>
      <div class="cols2">
        <pd-input
            type="text"
            id="titulo"
            v-model="notificacion.titulo"
            placeholder="Titulo"
            label="Título"
        ></pd-input>
      </div>
      <pd-input
          type="file"
          id="archivo"
          placeholder="Archivo"
          label="PDF"
      ></pd-input>
      <div class="form-group my-1">
        <label class="form-label">Contenido</label>
        <quill-editor
            v-model="notificacion.contenido"
            :options="editorOption"
        ></quill-editor>
      </div>

      <table-list
          :showFilter="true"
          :data="listadoDePersonas"
          :header="firmasTableList.headers"
          :body="firmasTableList.body"
          :actionButton="firmasTableList.actionButton"
          @rowClicked="deleteEmpleadoFromList"
      ></table-list>
      <button class="btn btn-primary" @click="save">Guardar</button>
    </div>
    <div class="notificaciones">
      <div class="card">
        <h4>Previsualización de cartelera</h4>
        <hr/>
        <div class="notificaciones">
          <div class="card" :class="classNotificacion">
            <h4 class="card-title">{{ notificacion.titulo }}</h4>
          </div>
        </div>
        <h4 class="mt-2">Previsualización de notificacion completa</h4>
        <hr/>
        <div>
          <h2 class="card-title text-center mt-2 mb-2">{{ notificacion.titulo }}</h2>
          <div class="d-flex" v-if="notificacion.archivo_url">
            <embed
                :src="notificacion.archivo_url"
                style="width: 100%; height: 29.7cm"
                frameborder="0"
            />
          </div>
          <div style="padding: 0 5px" v-html="notificacion.contenido" v-else></div>
        </div>
      </div>
    </div>

    <modal v-if="openAddEmpleado" @close="closeAddEmpleado">
      <AddEmpleadoParaNotificar
          slot="modal-body"
          :personas="listaDePersonasParaSeleccionar"
          :tablelist="personasTableList"
          @addEmpleado="addEmpleadoToList"
      />
    </modal>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import {quillEditor} from 'vue-quill-editor';
import Swal from 'sweetalert2';
import {NotificacionesServices} from '../../notificaciones/services/NotificacionesServices';
import TableList from '@/components/elements/TableList';
import {PersonasService} from '../../personas/services/PersonasServices';
import Modal from '@/components/elements/Modal';
import AddEmpleadoParaNotificar from '../../procedimientos/views/AddEmpleadoParaNotificar.vue';

export default {
  name: 'EditarNotificacion',
  components: {
    quillEditor,
    TableList,
    Modal,
    AddEmpleadoParaNotificar,
  },
  data() {
    return {
      notificacion: null,
      editorOption: {
        theme: 'snow',
      },
      openAddEmpleado: false,
      firmasTableList: {
        headers: [
          {value: 'Legajo', sortable: true},
          {value: 'Persona', sortable: true},
          {value: 'Gerencia', sortable: true},
          {value: 'Area', sortable: true},
          {value: 'Notificado', sortable: true},
        ],
        body: [
          {
            class: 'w-100',
            data: [
              {
                value: 'user.persona.numero_legajo',
                class: '',
                label: 'Legajo',
              },
            ],
          },
          {
            class: 'important',
            data: [
              {value: 'user.persona.nombre', class: 'text-primary'},
              {
                value: 'user.persona.cuil',
                class: 'text-small text-secondary',
                mutator: (cuil) => {
                  return PersonasService.formatCuil(cuil);
                },
              },
            ],
          },
          {
            class: 'w-100',
            data: [
              {value: 'user.persona.gerencia.descripcion', label: 'Gerencia'},
            ],
          },
          {
            class: 'w-100',
            data: [{value: 'user.persona.area.descripcion', label: 'Area'}],
          },
          {
            class: 'important',
            data: [
              {
                value: 'firmado',
                mutator: (item) => {
                  return item ? 'SI' : 'NO';
                },
              },
            ],
          },
        ],
        actionButton: {
          text: 'Añadir',
          action: () => {
            this.openAddEmpleado = true;
          },
        },
      },
      personasTableList: {
        headers: [
          {value: 'Legajo', sortable: true},
          {value: 'Persona', sortable: true},
          {value: 'Gerencia', sortable: true},
          {value: 'Area', sortable: true},
        ],
        body: [
          {
            class: 'w-100',
            data: [{value: 'numero_legajo', class: '', label: 'Legajo'}],
          },
          {
            class: 'important',
            data: [
              {value: 'nombre', class: 'text-primary'},
              {
                value: 'cuil',
                class: 'text-small text-secondary',
                mutator: (cuil) => {
                  return PersonasService.formatCuil(cuil);
                },
              },
            ],
          },
          {
            class: 'w-100',
            data: [{value: 'gerencia.descripcion', label: 'Gerencia'}],
          },
          {
            class: 'w-100',
            data: [{value: 'area.descripcion', label: 'Area'}],
          },
        ],
      },
      personas: [],
      usuarios_notificables: [],
    };
  },
  computed: {
    classNotificacion() {
      let clase = '';
      if (this.notificacion.tipo) {
        clase = `card-${this.notificacion.tipo}`;
      }
      return clase;
    },
    listadoDePersonas() {
      return this.usuarios_notificables.filter((firma) => {
        if (firma.deleted === true) {
          return false;
        }
        return true;
      });
    },
    listaDePersonasParaSeleccionar() {
      return this.personas.filter((persona) => {
        let exists = true;
        this.usuarios_notificables.forEach((firma) => {
          if (firma.user.persona.id === persona.id && firma.deleted === false) {
            exists = false;
          }
        });
        return exists;
      });
    },
  },
  created() {
    const notificacionId = this.$route.params.id;
    PersonasService.api.getAll().then((personas) => (this.personas = personas));
    NotificacionesServices.api
        .find(notificacionId)
        .then((response) => {
          this.notificacion = response.notificacion;
          this.usuarios_notificables = this.notificacion.notificacion_usuario;
          this.usuarios_notificables.forEach((firma) => {
            firma.deleted = false;
          });
          this.usuarios_notificables.sort((a, b) => a.user.id - b.user.id);
        })
        .catch((error) => {
          Swal.fire('Error', `${error}`, 'error');
        });
  },
  methods: {
    save() {
      const formData = new FormData();
      formData.append('titulo', this.notificacion.titulo);
      formData.append('contenido', this.notificacion.contenido);
      const archivo = document.querySelector('input#archivo');
      formData.append('archivo', archivo.files[0]);
      formData.append(
          'empleados_notificables',
          JSON.stringify(this.usuarios_notificables),
      );
      NotificacionesServices.api
          .put(this.notificacion.id, formData)
          .then((response) => {
            if (response.success) {
              Swal.fire('Éxito', 'Se guardó correcamente', 'success');
              this.$router.push({name: 'ListarNotificacionesAdmin'});
            } else {
              Swal.fire('Error', 'No se pudo guardar', 'error');
            }
          })
          .catch((error) => {
            Swal.fire(
                'Error',
                `<p>Error de servidor</p><p>${error}</p>`,
                'error',
            );
          });
    },
    deleteEmpleadoFromList(firma) {
      if (!firma.firmado) {
        Swal.fire({
          title: 'Confirme',
          html: `<p>¿Está seguro que quitar a <b>${firma.user.persona.nombre}</b> de la lista?</p>`,
          icon: 'question',
          showConfirmButton: true,
          confirmButtonText: 'Eliminar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            const index = this.usuarios_notificables.findIndex(
                (item) => item === firma,
            );

            firma.deleted = true;

            this.$set(this.usuarios_notificables, index, firma);
          }
        });
      }
    },
    addEmpleadoToList(empleado) {
      const alreadyLoadedEmpleado = this.usuarios_notificables.find(
          (firma) => firma.user.persona.id === empleado.id,
      );
      if (alreadyLoadedEmpleado) {
        alreadyLoadedEmpleado.deleted = false;
      } else {
        this.usuarios_notificables.push(
            this.createNewFirmaFromPersona(empleado),
        );
      }
      this.usuarios_notificables.sort((a, b) => a.user.id - b.user.id);
    },
    closeAddEmpleado() {
      this.openAddEmpleado = false;
    },
    createNewFirmaFromPersona(empleado) {
      const newFirma = {
        firmado: 0,
        id: null,
        notificacion_id: this.notificacion.id,
        user: empleado.user,
        usuario_id: empleado.user.id,
        added: true,
        deleted: false,
      };
      newFirma.user.persona = {
        id: empleado.id,
        numero_legajo: empleado.numero_legajo,
        nombre: empleado.nombre,
        cuil: empleado.cuil,
        gerencia: empleado.gerencia,
        area: empleado.area,
      };
      return newFirma;
    },
  },
};
</script>
